<template>
  <div  :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <Confirmation @action-delete="deleted()"/>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filter" scrollable persistent max-width="300px">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 text-first d-flex mb-4" style="background-color: #F05326;height:50px">
          <span class="white--text">
            Dialog Filter
          </span>
          <v-spacer></v-spacer>
          <v-icon size="20" @click="dialog.filter = false" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text style="height: 300px;" class="py-5">
          <div class="mb-2">Status</div>
          <v-select
            dense
            outlined
            color="#F05326"
            placeholder="Pilih"
            item-text="text"
            item-value="value"
            :items="status">
          </v-select>
          <div class="mb-2">Level</div>
          <v-select
            dense
            outlined
            color="#F05326"
            placeholder="Pilih"
            item-text="text"
            item-value="value"
            :items="level">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="$store.state.process.run"
            @click="dialog.filter = false">
            Reset
          </v-btn>
          <v-btn
            small
            width="70"
            color="#F05326"
            elevation="0"
            :disabled="$store.state.process.run"
            :loading="$store.state.process.run"
            class="white--text text-capitalize"
            @click="toFilter()">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">List Course</div>

      <!-- <Breadcrumbs :title="`Add New Questions`" :items="items"/> -->
      <Snackbar ref="snackbar"/>

      <v-row justify="space-between" class="mt-2" v-if="!$store.state.process.run || searching != ''">
        <v-col cols="4" v-if="!$store.state.process.run || searching != ''">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Cari Course . . ."
              autocomplete="off"
              v-model="searching"
              clearable
              color="#F05326"
              @click:clear="searching = '';"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div class="d-flex float-right"  v-if="!$store.state.process.run && list.total > 0">
            <v-btn 
              depressed
              dense
              elevation="0"
              color="#F5F5F5" 
              @click="filter"
              class="black--text text-capitalize mr-4">
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
            <v-btn
              color="#F05326"
              depressed
              dense
              @click="$router.push(`/course/create`)"
              class="white--text text-capitalize">
              Add New Course
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="text-center mt-7" v-if="$store.state.process.run == false && list.total < 1">
        <div class="mt-5">
          <v-img :src="require('@/assets/img/empty.png')" width="200" class="mx-auto"></v-img>
        </div>
        <div class="mt-3">Please add new course</div>
        <div class="mt-2">by click the button below</div>
        <v-btn
          color="#F05326"
          depressed
          dense
          @click="$router.push('/course/create')"
          class="mt-3 white--text text-capitalize">
          Add New Course
        </v-btn>
      </div>

      <div v-if="$store.state.process.run" class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table">
        </v-skeleton-loader>
      </div>

      <div class="mt-3 mb-8" v-if="!$store.state.process.run && list.total > 0">
        <v-data-table
          hide-default-footer
          fixed-header
          :loading="$store.state.process.run"
          :headers="headers"
          :items="list.list"
          :items-per-page.sync="list.limit"
          :page.sync="list.page"
          :server-items-length="list.total"
          item-key="id"
          :footer-props="{
            itemsPerPageText : 'Data per halaman',
            'items-per-page-options': [5,10, 20, 50, 100, 200]
          }"
          >
          <template slot="footer" slot-scope="{ props }">
            <Pagination 
              @reload="fetch(page)"
              @changeLimit="limit = $event; fetch()" 
              @changePage="page = $event; fetch($event)" 
              :model="props"/>
          </template>
          <template v-slot:[`item.level`]="{ item }">
            <div class="text-capitalize">{{ item.level }}</div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :ripple="false" 
              label
              small
              :color="item.status != 'published' ? '#E0E0E0' : '#E3F2FD'" 
              :style="item.status != 'published' ? 'color:#000000' : 'color:#01579B'">
              <div class="text-capitalize">{{ item.status }}</div>
            </v-chip>
            <!-- <v-edit-dialog
              :return-value.sync="item.status"
              large
              persistent
              color="#F05326"
              @save="updateStatus(item)"
              cancelText="Tutup"
              saveText="Simpan">
              <div>
              </div>
              <template v-slot:input>
                <v-select
                  outlined
                  dense
                  class="px-3 pt-3"
                  color="#F05326"
                  label="Status"
                  v-model="item.status"
                  item-text="text"
                  item-value="value"
                  :items="status">
                </v-select>
              </template>
            </v-edit-dialog> -->
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs ,on }">
                <v-btn
                  small
                  @click="updateStatus(item)"
                  :color="item.status == 'draft' ? 'teal' : 'orange darken-1'"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  icon>
                  <v-icon> {{ item.status == 'draft' ? 'mdi-upload-outline' : 'mdi-folder-clock-outline' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.status == 'draft' ? 'Publish' : 'Draft' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs ,on }">
                <v-btn
                  small
                  v-bind="attrs"
                  color="#F05326"
                  v-on="on"
                  class="mr-2"
                  @click="AddTopic(item)"
                  icon>
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Add Topic</span>
            </v-tooltip>
            <v-menu
              offset-y
              transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  v-bind="attrs"
                  v-on="on">
                  <v-icon :color="$vuetify.theme.dark ? 'white' : 'blue-grey darken-1'">mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="EditCourse(item)">
                  <v-list-item-title>Edit Course</v-list-item-title>
                </v-list-item>
                <v-list-item @click="confirmation(item.id)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>

    </div>
  </div>
</template>

<script>
import GridTableCourse from '@/components/course/GridTableCourse.vue'
import Pagination from '@/components/Pagination.vue'
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import Confirmation from '@/components/Confirmation.vue'
export default {
  components: { GridTableCourse, Pagination, Breadcrumbs, Snackbar, Confirmation },
  data() {
    return {
      id_course: "",
      searching: "",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      items: {
        total: 10
      },
      status: [
        { text: "Draft" , value: "draft" },
        { text: "Published" , value: "published" }
      ],
      level: [
        { text: "Easy" , value: "easy" },
        { text: "Medium" , value: "medium" },
        { text: "Advance" , value: "advance" }
      ],
      headers: [
        { text: 'Course Name', align: 'start', value: 'title', sortable: false  },
        // { text: 'Category', align: 'center', value: 'category', sortable: false  },
        { text: 'Level', align: 'center', value: 'level', sortable: false  },
        { text: 'Status', align: 'center', value: 'status', sortable: false  },
        { text: '', value: 'action',align: "end", sortable: false  }
      ],
      list: {},
      dialog: {
        filter: false,
      },
      process: {
        run: false
      },
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
  },
  computed:{},
  created(){
    this.fetch()
  },
  mounted(){},
  methods:{
    /**
     * METHOD LIST
     */
    async updateStatus(item){
      let statuses = item.status == 'draft' ? 'published' : 'draft'
      this.$store.state.process.run = true
      await put(`api/v1/package/update_status/${item.id}`,{
        data: {
          status: statuses
        }
      })
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.$refs.snackbar.open("#4CAF50", `New Status Updated Succesfully`);
          this.$store.state.process.run = false
          this.fetch()
        }else {
          this.$refs.snackbar.open("error", `New Status Updated Failed`);
          this.$store.state.process.run = false
          this.fetch()
        }
      })
    },
    AddTopic(item){
      this.$router.push(`/topic/${item.id}`)
    },
    EditCourse(item){
      this.$router.push(`/course/update/${item.id}`)
    },
    resetFilter(){},
    filter(){
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    toFilter(){},
    confirmation(id){
      this.$store.state.dialog.confirmDelete = true
      this.id_course = id
    },
    async deleted(){
      this.$store.state.process.run = true
      await destroy(`api/v1/package/delete/${this.id_course}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.$refs.snackbar.open("#4CAF50", `Course Deleted Succesfully`);
          this.fetch()
        }else{
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.$refs.snackbar.open("#4CAF50", `Course Deleted Succesfully`);
          this.fetch()
        }
      })
    },
    async fetch(paging){
      this.$store.state.process.run = true;
      this.$set(this.items, "list", []);
      await get("api/v1/package/list", {
        params: {
          page: paging,
          limit: this.limit
        }
        }).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.$store.state.process.run = false;
            this.list = res.data;
          }else{
            this.$store.state.process.run = false;
          }
        });
    },
    /**
     * METHOD LIST END
     */
  }
}
</script>

<style>
.v-small-dialog__actions .v-btn .v-btn__content {
  color: #F05326
}
</style>